import Vue from 'vue';
import _ from 'lodash';
import moment from 'moment';

import BaseModel from './BaseModel';
import FileModel from './FileModel';
import UserModel from './UserModel';
import TaskModel from './TaskModel';
import TaskTimeModel from './TaskTimeModel';
import { PropertyLocationModel } from './LocationModels';
import { titleCase } from '../util';
import filters from '../filters';

class EventDetailModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.start_time = data.start_time ? new Date(data.start_time) : null;
    this.end_time = data.end_time ? new Date(data.end_time) : null;
    this.location = data.location ? PropertyLocationModel.factory(data.location) : null;
  }

  get name() {
    return 'EventDetailModel'; // override in child class
  }
}

const ReservationRoutes = [
  'reservations',
  'reservations-calendar',
  'reservations-archived',
  'reservations-calendar-archived',
];

const ReservationRequestStatus = {
  PENDING_REVIEW: 'pending_review',
  CANCELED: 'canceled',
  REVIEWED: 'reviewed',
  EXPIRED: 'expired',
};

const ReservationStatus = {
  PENDING_REVIEW: 'pending_review',
  CANCELED: 'canceled',
  DENIED: 'denied',
  APPROVED: 'approved',
};

class ReservationModel extends EventDetailModel {
  constructor(data, args) {
    data.depthSingle = args && 'depthSingle' in args ? args.depthSingle : true;
    super();
    this.init(data || {});
  }

  init(data) {
    if (Object.keys(data).length === 0) return;

    super.init(data); // see EventDetailModel

    this.user = data.user ? new UserModel(data.user) : null;
    this.status = data.status || 'approved';
    this.notify_days_before_end_time = data.notify_days_before_end_time || 1;

    if (!data.depthSingle) {
      this.request = data.request ? new ReservationRequestModel(data.request) : null;
    } else {
      this.request = data.request ? new ReservationRequestModel({ id: data.request.id, name: data.request.name, description: data.request.description }) : null;
    }

    this.tasks = (data.tasks || []).map(t => new TaskModel(t));

    if ('available' in data) {
      this.available = data.available;
    }

    this.commentCount = data.comment_count || 0;
    this.unreadCommentCount = data.unread_comment_count || 0;
  }

  get duration() {
    const time_in_seconds = moment(this.end_time).diff(moment(this.start_time), 'second');
    const model = new TaskTimeModel({
      start_time: this.start_time,
      end_time: this.end_time,
      time_in_seconds,
    });
    return model.display_value;
  }

  get durationInDays() {
    if (!this.start_time || !this.end_time) return 0;

    return moment(this.end_time).diff(this.start_time, 'days') + 1;
  }

  get startWeek() {
    return moment(this.start_time).week();
  }

  get endWeek() {
    return moment(this.end_time).week();
  }

  /** does event span multiple calendar weeks? meaningful for calendar rendering */
  get spansWeeks() {
    return this.endWeek > this.startWeek;
  }

  get name() {
    if (this.request) {
      return this.request.name;
    }
    return '';
  }

  get requesterName() {
    return this.user?.fullName || '';
  }

  get display_label() {
    if (this.request) {
      return `${this.request.name} (${filters.dateShort(this.start_time)}, ${filters.timeOnly(this.start_time)})`;
    }
    return '';
  }

  get detailDisplay() {
    if (!this.start_time || !this.location) return '';

    const timeOfDay = moment(this.start_time).format('h:mmA');
    const location = this.location?.name;

    return `<strong>${timeOfDay}</strong> - ${location}`;
  }

  get archived() {
    return moment(this.end_time).isBefore(moment()); // does this need approved status too?
  }

  get isReadOnly() {
    return this.archived || this.status === ReservationStatus.CANCELED || this.status === ReservationStatus.DENIED;
  }

  get canCancel() {
    return this.status === ReservationStatus.PENDING_REVIEW || (this.status === ReservationStatus.APPROVED && !this.archived);
  }

  get readOnlyMessageDetails() {
    let icon = ['fas', 'circle-exclamation'];
    let iconClass = {};
    let statusVerb = 'been canceled';

    if (this.status !== ReservationStatus.CANCELED) {
      icon = ['fas', 'check-circle'];
      iconClass = { green: true };
      statusVerb = 'already occurred';
    }

    if (this.archived) {
      iconClass = {}; // unset = gray
    }

    const message = `This reservation has ${statusVerb}. The information shown below is read-only.`;

    return {
      icon,
      iconClass,
      message,
    };
  }

  get calendarClass() {
    let classes = {};

    if (moment(this.end_time).isBefore(moment()) && this.status !== ReservationStatus.CANCELED) {
      classes = { past: true };
    } else {
      classes[this.status] = true;
    }

    if (this.spansWeeks) {
      classes.multiweek = true;
    }

    if (this.editing) {
      classes.editing = true;
    }

    return classes;
  }
}

class ReservationRequestModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.name = data.name || '';
    this.description = data.description || '';
    this.created = data.created ? new Date(data.created) : null;
    this.status = data.status || '';
    this.reviewed_status = data.reviewed_status || '';
    this.user = data.user ? new UserModel(data.user) : null;
    this.reviewers = (data.reviewers || []).map(a => new UserModel(a));
    this.reviewed_by = data.reviewed_by ? new UserModel(data.reviewed_by) : null;
    this.attachments = (data.attachments || []).map(f => new FileModel(f));
    this.reservations = (data.reservations || []).map(r => new ReservationModel(r));

    this.commentCount = data.comment_count || 0;
    this.unreadCommentCount = data.unread_comment_count || 0;
  }

  get isReadOnly() {
    return this.reviewed_status === 'denied'
      || this.reviewed_status.includes('approved')
      || this.status === 'canceled'
      || this.status === 'expired';
  }

  get readOnlyMessageDetails() {
    let icon = ['fas', 'circle-exclamation'];

    if (this.reviewed_status.includes('approved')) {
      icon = ['fas', 'circle-check'];
    } else if (this.reviewed_status === 'denied') {
      icon = ['far', 'ban'];
    }

    let iconClass = {};

    if (this.reviewed_status.includes('approved')) {
      iconClass = { green: true };
    } else if (this.reviewed_status === 'denied') {
      iconClass = { red: true };
    }

    const statusVerb = (this.reviewed_status || 'approved').split('_').join(' ');
    const message = `This reservation request has ${(statusVerb === 'expired') ? '' : 'been '}${statusVerb}. The information below is read-only.`;

    return {
      icon,
      iconClass,
      message,
    };
  }

  get requesterName() {
    return this.user?.fullName || '';
  }

  get reviewerName() {
    return this.reviewed_by?.fullName || '';
  }

  get statusDisplay() {
    return titleCase(this.status);
  }

  get reviewedStatusDisplay() {
    if (this.reviewed_status === 'partial') {
      return 'Approved (Partial)';
    }
    return titleCase(this.reviewed_status || this.status || Vue.prototype.$globals.defaultDisplayValue);
  }

  get canCancel() {
    return this.status === 'pending_review' && this.user?.id === Vue.prototype.$globals.user()?.id;
  }

  // a de-duplicated list of start times - mostly to see if we have multiple
  get reservationStartTimes() {
    if (!this.reservations.length) return [];

    return _.uniqWith(this.reservations, (a, b) => (a.start_time?.toString() === b.start_time?.toString())).map(r => r.start_time);
  }

  get hasMultipleStartTimes() {
    return this.reservationStartTimes.length > 1;
  }

  get reservationEndTimes() {
    if (!this.reservations.length) return [];

    return _.uniqWith(this.reservations, (a, b) => (a.end_time?.toString() === b.end_time?.toString())).map(r => r.end_time);
  }

  get hasMultipleEndTimes() {
    return this.reservationEndTimes.length > 1;
  }

  get reservationDurations() {
    if (!this.reservations.length) return [];
    return _.zip(this.reservationStartTimes, this.reservationEndTimes).map((r) => {
      const start_time = r[0];
      const end_time = r[1];
      const time_in_seconds = moment(end_time).diff(moment(start_time), 'second');
      const model = new TaskTimeModel({
        start_time,
        end_time,
        time_in_seconds,
      });
      return model.display_value;
    });
  }

  get hasMultipleDurations() {
    return this.reservationDurations.length > 1;
  }

  get reservationLocations() {
    if (!this.reservations.length) return [];

    return _.uniqWith(this.reservations, (a, b) => (a.location?.id === b.location?.id)).map(r => r.location);
  }

  get hasMultipleLocations() {
    return this.reservationLocations.length > 1;
  }

  get locationName() {
    return !this.hasMultipleLocations
      ? this.reservationLocations[0].fullAncestryPath
      : 'Multiple';
  }
}

export {
  EventDetailModel,
  ReservationRoutes,
  ReservationRequestStatus,
  ReservationStatus,
  ReservationModel,
  ReservationRequestModel,
};
