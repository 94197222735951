import BaseModel from './BaseModel';

import TerritoryModel from './TerritoryModel';
import ThirdPartyPaymentInfoModel from './ThirdPartyPaymentInfoModel';
import FileModel from './FileModel';
import AffiliateModel from './AffiliateModel';
import TaskTimeModel from './TaskTimeModel';
import SensorConnectModel from './SensorConnectModel';
import { formatPhoneNumber } from '../filters/formatters.js';

const spTypes = {
  isp: 'Independent Service Provider',
  csc: 'Customer Service Center',
  nca: 'National Corporate Account',
};

const eoTypes = {
  corporate: 'Corporate Account',
  campus: 'Campus',
  facility: 'Facility',
};

class CompanyModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    // set in django admin this.demo_seed_company = data.demo_seed_company || false;

    this.ignore_stripe_requirement = data.ignore_stripe_requirement || false;
    this.parent = data.parent ? new CompanyModel(data.parent) : null;
    this.children = (data.children || []).map(c => new CompanyModel(c));

    this.tax_exempt = data.tax_exempt || false;
    this.tax_exempt_document = data.tax_exempt_document ? new FileModel(data.tax_exempt_document) : null;

    this.equipment_manufacturers_serviced = data.equipment_manufacturers_serviced || '';
    this.equipment_categories_serviced = data.equipment_categories_serviced || '';
    this.services_you_provide = data.services_you_provide || '';

    this.hours_of_operation = data.hours_of_operation || '';

    if (this.hours_of_operation === 'null') {
      // bandaid
      this.hours_of_operation = '';
    }

    this.territories = (data.territories || []).map(c => new TerritoryModel(c));

    // from /me
    this.has_children = data.has_children || false;

    // from get
    this.children = (data.children || []).map(c => new CompanyModel(c));

    this.helix_fee_percentage_one_time_service = parseFloat(data.helix_fee_percentage_one_time_service) || 0;
    this.helix_fee_percentage_service_agreement = parseFloat(data.helix_fee_percentage_service_agreement) || 0;
    this.helix_fee_percentage_parts_order = parseFloat(data.helix_fee_percentage_parts_order) || 0;
    this.helix_fee_percentage_new_equipment = parseFloat(data.helix_fee_percentage_new_equipment) || 0;
    this.helix_fee_percentage_rental_equipment = parseFloat(data.helix_fee_percentage_rental_equipment) || 0;
    this.helix_fee_percentage_energy_assessment = parseFloat(data.helix_fee_percentage_energy_assessment) || 0;

    this.created = data.created ? new Date(data.created) : null;
    this.name = data.name || '';
    this.logo = data.logo_url || data.logo || '';

    if (this.logo && this.logo.signed_url) {
      this.logo = this.logo.signed_url;
    }

    this.affiliate = data.affiliate ? new AffiliateModel(data.affiliate) : null;

    this.street_address = data.street_address || '';
    this.street_address_line_2 = data.street_address_line_2 || '';
    this.latitude = (data.location && data.location.latitude) || 0;
    this.longitude = (data.location && data.location.longitude) || 0;
    this.city = data.city || '';
    this.state = data.state || '';
    this.zip = data.zip || '';
    this.website = data.website || '';
    this.phone = data.phone || '';
    this.phone_ext = data.phone_ext || '';
    this.description = data.description || '';

    this.is_equipment_operator = data.is_equipment_operator || false;
    this.is_service_provider = data.is_service_provider || false;
    this.company_role = data.company_role || '';
    this.company_permissions = data.company_permissions || [];
    this.platform_permissions = data.platform_permissions || [];
    this.is_requesting_service_provider_access = data.is_requesting_service_provider_access || false;

    this.first_admin_email = data.first_admin_email || '';
    this.first_admin_first_name = data.first_admin_first_name || '';
    this.first_admin_last_name = data.first_admin_last_name || '';
    this.last_activity = data.last_activity ? new Date(data.last_activity) : null;
    this.equipment_count = data.equipment_count || 0;
    this.open_rfp_count = data.open_rfp_count || 0;

    this.service_provider_type = data.service_provider_type || ''; // isp|csc|nca
    this.equipment_operator_type = data.equipment_operator_type || ''; // corporate|campus|facility

    this.how_did_you_hear_about_us = data.how_did_you_hear_about_us || '';
    this.how_did_you_hear_about_us_explanation = data.how_did_you_hear_about_us_explanation || '';
    this.industry = data.industry || '';
    this.industry_other = data.industry_other || '';
    this.naic_code = data.naic_code || 0;
    this.salesforce_id = data.salesforce_id || '';

    if (data.naic_code && data.naic_code.code) {
      this.naic_code = data.naic_code.code;
    }

    if (data.contact) {
      this.contact_first_name = data.contact.first_name;
      this.contact_last_name = data.contact.last_name;
      this.contact_email = data.contact.email;
    }

    this.affiliate_knowledge_center_id = data.affiliate_knowledge_center?.id || 0;

    // just on admin companies list
    this.stripe_connected_account_id = data.stripe_connected_account_id || '';
    this.charges_enabled = data.stripe_connected_account && data.stripe_connected_account.charges_enabled;
    this.details_submitted = data.stripe_connected_account && data.stripe_connected_account.details_submitted;
    this.payouts_enabled = data.stripe_connected_account && data.stripe_connected_account.payouts_enabled;

    // for view-invoice modal
    this.email_address = data.email_address || data.email || '';
    this.email = this.email_address;

    if (data.third_party_payment_info && !data.third_party_payment_info.active) {
      this._inactive_third_party_info_id = data.third_party_payment_info.id;
    }

    if (data.third_party_payment_info && data.third_party_payment_info.active) {
      this.third_party_payment_info = data.third_party_payment_info ? new ThirdPartyPaymentInfoModel(data.third_party_payment_info) : null;
    } else {
      this.third_party_payment_info = null;
    }

    this.client_company_number = data.client_company_number || '';
    this.client_policy_number = data.client_policy_number || '';
    this.affiliate_policy_number = data.affiliate_policy_number || '';

    this.has_service_marketplace_analytics = data.has_service_marketplace_analytics || false;
    this.requires_affiliate_data_verification = data.requires_affiliate_data_verification || false;

    this.affiliate_data_verification = {};
    if (data.affiliate_data_verification) {
      this.affiliate_data_verification.created = data.affiliate_data_verification.created ? new Date(data.affiliate_data_verification.created) : null;
      this.affiliate_data_verification.email = data.affiliate_data_verification.email || null;
    }

    this.is_helix_test_company = data.is_helix_test_company || false;
    this.vendor_hub_special_offer = data.vendor_hub_special_offer || '';
    this.vendor_hub_special_offer_title = data.vendor_hub_special_offer_title || '';
    this.vendor_hub_featured = data.vendor_hub_featured || false;

    this.active_task_time_tracking = data.active_task_time_tracking ? new TaskTimeModel(data.active_task_time_tracking) : null;

    this.sensor_connect = data.sensor_connect?.id ? new SensorConnectModel(data.sensor_connect) : null;
  }

  get isInsuranceCustomer() {
    return this.affiliate?.root_name === 'HSB';
  }

  get hasStripe() {
    return this.charges_enabled && this.details_submitted && this.payouts_enabled;
  }

  get position() {
    if (this.latitude && this.longitude) {
      return {
        lat: this.latitude,
        lng: this.longitude,
      };
    }
    return null;
  }

  get fullAddress() {
    if (this.street_address) {
      return `${this.street_address} ${this.city}, ${this.state} ${this.zip}`;
    }
    return '';
  }

  get hasFullAddress() {
    return this.street_address && this.city && this.state && this.zip;
  }

  get cityStateDisplay() {
    if (this.city && this.state) {
      return `${this.city}, ${this.state}`;
    }

    return this.city || this.state || '';
  }

  get fullPhone() {
    if (this.phone_ext) {
      return `${this.formattedPhone} x${this.phone_ext}`;
    }

    return this.formattedPhone;
  }

  get hoursJson() {
    if (typeof this.hours_of_operation === 'object') {
      return this.hours_of_operation;
    }
    return this.hours_of_operation && JSON.parse(this.hours_of_operation);
  }

  get displayName() {
    return this.name;
  }

  get display_label() {
    return this.displayName;
  }

  get hasMultipleRoles() {
    return this.is_service_provider && this.is_equipment_operator;
  }

  get eoName() {
    let eoName = '';
    if (this.is_equipment_operator) {
      eoName = this.equipment_operator_type ? eoTypes[this.equipment_operator_type] : 'Equipment Operator';
    }
    return eoName;
  }

  get spName() {
    let spName = '';
    if (this.is_service_provider) {
      spName = this.service_provider_type ? spTypes[this.service_provider_type] : 'Vendor';
    }
    return spName;
  }

  get oem_type() {
    if (this.is_equipment_operator && this.is_service_provider) {
      return `${this.eoName} & ${this.spName}`;
    }

    if (this.is_equipment_operator) {
      return this.eoName;
    } if (this.is_service_provider) {
      return this.spName;
    }
    return '';
  }

  get assigned_helix_admin() {
    if (this.first_admin_first_name || this.first_admin_last_name) {
      return `${this.first_admin_first_name} ${this.first_admin_last_name}`;
    }
    return '';
  }

  get formattedPhone() {
    return formatPhoneNumber(this.phone) || '';
  }
}

CompanyModel.spTypes = spTypes;
CompanyModel.eoTypes = eoTypes;
export default CompanyModel;
