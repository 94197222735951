const routes = [
  {
    path: '/',
    name: 'home',
    allowAnonymous: true,
    component: () => import('@/views/Home.vue'),
    meta: {
      title: 'Home | HelixIntel',
      allowAnonymous: true,
    },
  },
  {
    path: '/contact',
    name: 'contact',
    allowAnonymous: true,
    component: () => import('@/views/Contact.vue'),
    meta: {
      title: 'Contact | HelixIntel',
      allowAnonymous: true,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/DashboardV3.vue'),
    meta: {
      title: 'Dashboard | HelixIntel',
      layout: 'sidebar-layout',
      eoOnly: true,
    },
  },
  {
    path: '/affiliate',
    name: 'affiliate',
    component: () => import('@/views/AffiliateDashboard.vue'),
    meta: {
      title: 'Affiliate Dashboard | HelixIntel',
      layout: 'sidebar-layout',
      affiliateOnly: true,
    },
  },
  {
    path: '/affiliate/managers',
    name: 'affiliate-managers',
    component: () => import('@/views/AffiliateManagers.vue'),
    meta: {
      title: 'Affiliate Managers | HelixIntel',
      layout: 'sidebar-layout',
      affiliateOnly: true,
    },
  },
  {
    path: '/affiliate/risk/surveys/:id/:status?',
    name: 'affiliate-risk-assessment-view',
    component: () => import('@/views/AffiliateRiskAssessmentView.vue'),
    meta: {
      title: 'Risk Assessment | HelixIntel',
      layout: 'sidebar-layout',
      affiliateOnly: true,
    },
  },
  {
    path: '/affiliate/risk/surveys/:status?',
    name: 'affiliate-risk-assessment',
    component: () => import('@/views/AffiliateRiskAssessmentList.vue'),
    meta: {
      title: 'Risk Assessment | HelixIntel',
      layout: 'sidebar-layout',
      affiliateOnly: true,
    },
  },
  {
    path: '/affiliate/risk/surveys/:surveyId/submission/:submissionId?/:status?',
    name: 'affiliate-risk-assessment-survey',
    component: () => import('@/views/AffiliateRiskAssessmentSurvey.vue'),
    meta: {
      title: 'Risk Assessment Survey | HelixIntel',
      layout: 'sidebar-layout',
      affiliateOnly: true,
    },
  },
  {
    path: '/risk/surveys/',
    name: 'risk-assessment',
    component: () => import('@/views/RiskAssessmentList.vue'),
    meta: {
      title: 'Risk Assessment | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/risk/surveys/:id',
    name: 'risk-assessment-view',
    component: () => import('@/views/RiskAssessmentView.vue'),
    meta: {
      title: 'Risk Assessment | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/risk/surveys/:surveyId/submission/:submissionId?',
    name: 'risk-assessment-survey',
    component: () => import('@/views/RiskAssessmentSurvey.vue'),
    meta: {
      title: 'Risk Assessment Survey | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/affiliate/equipment',
    name: 'affiliate-equipment',
    component: () => import('@/views/AffiliateEquipment.vue'),
    meta: {
      title: 'Affiliate Equipment | HelixIntel',
      layout: 'sidebar-layout',
      affiliateOnly: true,
    },
  },
  {
    path: '/affiliate/equipment/:id',
    name: 'affiliate-equipment-view',
    component: () => import('@/views/AffiliateEquipment.vue'),
    meta: {
      title: 'Affiliate Equipment | HelixIntel',
      layout: 'sidebar-layout',
      affiliateOnly: true,
    },
  },
  {
    path: '/affiliate/properties',
    name: 'affiliate-properties',
    component: () => import('@/views/AffiliateLocations.vue'),
    meta: {
      title: 'Affiliate Properties | HelixIntel',
      layout: 'sidebar-layout',
      affiliateOnly: true,
    },
  },
  {
    path: '/affiliate/properties/:id',
    name: 'affiliate-properties-view',
    component: () => import('@/views/AffiliateLocations.vue'),
    meta: {
      title: 'Affiliate Properties | HelixIntel',
      layout: 'sidebar-layout',
      affiliateOnly: true,
    },
  },
  {
    path: '/affiliate/users',
    name: 'affiliate-users',
    component: () => import('@/views/AffiliateUsers.vue'),
    meta: {
      title: 'Affiliate Users | HelixIntel',
      layout: 'sidebar-layout',
      affiliateOnly: true,
    },
  },
  {
    path: '/affiliate/companies',
    name: 'affiliate-companies',
    component: () => import('@/views/AffiliateCompanies.vue'),
    meta: {
      title: 'Affiliate Companies | HelixIntel',
      layout: 'sidebar-layout',
      affiliateOnly: true,
    },
  },
  {
    path: '/automated-workflows/:id?',
    name: 'automated-workflows',
    component: () => import('@/views/AutomatedWorkflows.vue'),
    meta: {
      title: 'Automated Workflows | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/announcements',
    name: 'announcements',
    component: () => import('@/views/Announcements.vue'),
    meta: {
      title: 'Announcements | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/owner',
    name: 'owner',
    component: () => import('@/views/OwnerDashboard.vue'),
    meta: {
      title: 'Owner | HelixIntel',
      layout: 'sidebar-layout',
      adminOnly: true,
    },
  },
  {
    path: '/owner/report',
    name: 'owner-report',
    component: () => import('@/views/OwnerReport.vue'),
    meta: {
      title: 'Owner Report | HelixIntel',
      layout: 'sidebar-layout',
      adminOnly: true,
    },
  },
  {
    path: '/owner/suggestions/:status?',
    name: 'owner-suggestions',
    component: () => import('@/views/OwnerSuggestions.vue'),
    meta: {
      title: 'Suggestions | HelixIntel',
      layout: 'sidebar-layout',
      adminOnly: true,
    },
  },
  {
    path: '/sp',
    name: 'sp-dashboard',
    redirect: { name: 'vendor-dashboard' },
  },
  {
    path: '/vendor',
    name: 'vendor-dashboard',
    component: () => import('@/views/SPDashboard.vue'),
    meta: {
      title: 'Vendor | HelixIntel',
      layout: 'sidebar-layout',
      spOnly: true,
    },
  },
  {
    path: '/profile-editor',
    name: 'profile-editor',
    component: () => import('@/views/ViewVendor.vue'),
    meta: {
      title: 'Edit Company Info | HelixIntel',
      layout: 'profile-editor-layout',
    },
  },
  {
    path: '/payment-settings',
    name: 'payment-settings',
    component: () => import('@/views/SPPaymentSettings.vue'),
    meta: {
      title: 'Payment Settings | HelixIntel',
      layout: 'sidebar-layout',
      spOnly: true,
    },
  },
  {
    path: '/payment-methods',
    name: 'payment-methods',
    component: () => import('@/views/EOPaymentMethods.vue'),
    meta: {
      title: 'Payment Methods | HelixIntel',
      layout: 'sidebar-layout',
      eoOnly: true,
    },
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: () => import('@/views/Onboarding.vue'),
    meta: {
      title: 'Onboarding | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/equipment',
    name: 'equipment',
    component: () => import('@/views/Equipment.vue'),
    meta: {
      title: 'Equipment | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/archived/equipment',
    name: 'archived-equipment',
    component: () => import('@/views/Equipment.vue'),
    meta: {
      title: 'Archived Equipment | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/equipment/:id',
    name: 'equipment-view',
    component: () => import('@/views/ViewEquipment.vue'),
    meta: {
      title: 'Equipment | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/equipment/:id/qr',
    name: 'equipment-view-qr',
    component: () => import('@/views/ViewEquipment.vue'),
    meta: {
      title: 'Equipment | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/parts',
    name: 'parts',
    component: () => import('@/views/Parts.vue'),
    meta: {
      title: 'Parts & Supplies | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/parts/bins',
    name: 'parts-bins',
    component: () => import('@/views/Parts.vue'),
    meta: {
      title: 'Part Bins | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/archived/parts',
    name: 'archived-parts',
    component: () => import('@/views/Parts.vue'),
    meta: {
      title: 'Archived Parts | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/archived/parts/bins',
    name: 'archived-parts-bins',
    component: () => import('@/views/Parts.vue'),
    meta: {
      title: 'Archived Part Bins | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/part/:id',
    name: 'part-view',
    component: () => import('@/views/ViewPart.vue'),
    meta: {
      title: 'Parts & Supplies | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/bin/:id',
    name: 'part-bin-view',
    component: () => import('@/views/ViewPartContainer.vue'),
    meta: {
      title: 'Part Bin | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/forgot',
    name: 'forgot',
    allowAnonymous: true,
    component: () => import('@/views/Forgot.vue'),
    meta: {
      title: 'Forgot Password | HelixIntel',
      allowAnonymous: true,
    },
  },
  {
    path: '/properties',
    name: 'properties',
    component: () => import('@/views/PropertyLocations.vue'),
    meta: {
      title: 'Properties | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/archived/properties',
    name: 'archived-properties',
    component: () => import('@/views/PropertyLocations.vue'),
    meta: {
      title: 'Archived Properties | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/location/:id',
    name: 'location-view',
    component: () => import('@/views/ViewLocation.vue'),
    meta: {
      title: 'Location | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/vendors',
    name: 'vendors',
    component: () => import('@/views/Vendors.vue'),
    meta: {
      title: 'My Vendors | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/vendors/archived',
    name: 'archived-vendors',
    component: () => import('@/views/Vendors.vue'),
    meta: {
      title: 'My Vendors | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/vendor/:id',
    name: 'vendor-view',
    component: () => import('@/views/ViewVendor.vue'),
    meta: {
      title: 'Vendor | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/customers',
    name: 'customers',
    component: () => import('@/views/CustomersV2.vue'),
    meta: {
      title: 'My Customers | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/customers/archived',
    name: 'archived-customers',
    component: () => import('@/views/CustomersV2.vue'),
    meta: {
      title: 'My Customers | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/customer/:id',
    name: 'customer-view',
    component: () => import('@/views/ViewCustomer.vue'),
    meta: {
      title: 'Customer | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/work-item/templates/archived',
    name: 'archived-work-item-templates',
    component: () => import('@/views/WorkItemTemplates.vue'),
    meta: {
      title: 'Work Item Templates | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/work-item/templates/:id',
    name: 'work-item-template',
    component: () => import('@/views/ViewWorkItemTemplate.vue'),
    meta: {
      title: 'Work Item Template | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/work-item/templates',
    name: 'work-item-templates',
    component: () => import('@/views/WorkItemTemplates.vue'),
    meta: {
      title: 'Work Item Templates | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/work-requests/new',
    name: 'new-work-request',
    component: () => import('@/views/WorkOrders.vue'),
    meta: {
      title: 'Work Requests | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/work-requests/processed/:id?',
    name: 'work-requests-processed',
    component: () => import('@/views/WorkOrders.vue'),
    meta: {
      title: 'Processed Work Requests | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/work-requests/archived',
    name: 'work-requests-archived',
    component: () => import('@/views/WorkOrders.vue'),
    meta: {
      title: 'Archived Work Requests | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/work-requests/:id?',
    name: 'work-requests',
    component: () => import('@/views/WorkOrders.vue'),
    meta: {
      title: 'Work Requests | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/work-request/:id?',
    redirect: { name: 'work-requests' },
  },
  {
    path: '/work-manager/calendar',
    name: 'tasks-calendar',
    component: () => import('@/views/TasksCalendar.vue'),
    meta: {
      title: 'Work Manager Calendar | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/tasks/calendar',
    redirect: { name: 'tasks-calendar' },
  },
  {
    path: '/work-manager/archived',
    name: 'archived-tasks',
    component: () => import('@/views/TaskGroups.vue'),
    meta: {
      title: 'Work Manager - Archived | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
  // This has to be below any of the routes with a fixed single element path
  // after `/work-manager/` to avoid conflicts
    path: '/work-manager/:id?',
    name: 'tasks',
    component: () => import('@/views/TaskGroups.vue'),
    meta: {
      title: 'Work Manager | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    // legacy links exist for this route
    path: '/task/:id?',
    redirect: { name: 'tasks' },
  },
  {
    path: '/tasks/:id?',
    redirect: { name: 'tasks' },
  },
  {
    path: '/archived/tasks',
    redirect: { name: 'archived-tasks' },
  },
  {
    path: '/work-manager/groups/archived',
    name: 'archived-groups',
    component: () => import('@/views/TaskGroups.vue'),
    meta: {
      title: 'Work Manager - Archived Groups | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/archived/groups',
    redirect: { name: 'archived-groups' },
  },
  {
    path: '/work-manager/calendar/archived',
    name: 'archived-tasks-calendar',
    component: () => import('@/views/TasksCalendar.vue'),
    meta: {
      title: 'Work Manager Calendar - Archived | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/archived/tasks/calendar',
    redirect: { name: 'archived-task-calendar' },
  },
  {
    path: '/pm-schedules/:id',
    name: 'pm-schedule',
    component: () => import('@/views/ViewPMSchedule.vue'),
    meta: {
      title: 'PM Schedule | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/pm-schedules',
    name: 'pm-schedules',
    component: () => import('@/views/PMSchedules.vue'),
    meta: {
      title: 'PM Schedules | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/rfp',
    name: 'rfp',
    component: () => import('@/views/RFP.vue'),
    meta: {
      title: 'My Requests | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/login',
    name: 'login',
    allowAnonymous: true,
    component: () => import('@/views/Login.vue'),
    meta: {
      title: 'Login | HelixIntel',
      allowAnonymous: true,
    },
  },
  {
    path: '/mobile-login',
    name: 'mobile-login',
    allowAnonymous: true,
    component: () => import('@/views/Login.vue'),
    meta: {
      title: 'Login | HelixIntel',
      allowAnonymous: true,
      layout: 'empty-layout',
    },
  },
  {
    path: '/users/sso/login/google/redirect',
    name: 'google-login',
    allowAnonymous: true,
    component: () => import('@/views/Login.vue'),
    meta: {
      title: 'Login | HelixIntel',
      allowAnonymous: true,
      layout: 'empty-layout',
    },
  },
  {
    path: '/users/sso/login/microsoft/redirect',
    name: 'microsoft-login',
    allowAnonymous: true,
    component: () => import('@/views/Login.vue'),
    meta: {
      title: 'Login | HelixIntel',
      allowAnonymous: true,
      layout: 'empty-layout',
    },
  },
  {
    path: '/account/claim',
    name: 'claim-account',
    allowAnonymous: true,
    component: () => import('@/views/CreateAccount.vue'),
    beforeEnter: () => {
      // Hacky way to redirect to the correct page if the navigate directly to the account claim page
      window.location.href = 'https://start.helixintel.com/General-Request-Access-Form';
    },
    meta: {
      title: 'Activate Your Account | HelixIntel',
      allowAnonymous: true,
    },
  },
  {
    path: '/reset/:token',
    name: 'reset',
    allowAnonymous: true,
    component: () => import('@/views/Reset.vue'),
    meta: {
      title: 'Reset Password | HelixIntel',
      allowAnonymous: true,
    },
  },
  {
    path: '/company/invite/confirm/:token',
    name: 'accept-invite',
    allowAnonymous: true,
    component: () => import('@/views/AcceptInvite.vue'),
    meta: {
      title: 'Accept Invite | HelixIntel',
      allowAnonymous: true,
    },
  },
  {
    path: '/confirm/:token',
    name: 'confirm',
    allowAnonymous: true,
    component: () => import('@/views/ConfirmEmail.vue'),
    meta: {
      title: 'Confirm Email | HelixIntel',
      allowAnonymous: true,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/Logout.vue'),
    allowAnonymous: true,
    meta: {
      title: 'Logout | HelixIntel',
      allowAnonymous: true,
    },
  },
  {
    path: '/register',
    name: 'register',
    allowAnonymous: true,
    component: () => import('@/views/Register.vue'),
    meta: {
      title: 'Register | HelixIntel',
      allowAnonymous: true,
    },
  },
  {
    path: '/admin/users',
    name: 'admin-users',
    component: () => import('@/views/AdminUsers.vue'),
    meta: {
      title: 'Admin Users | HelixIntel',
      layout: 'sidebar-layout',
      adminOnly: true,
    },
  },
  {
    path: '/admin/qr',
    name: 'admin-qr',
    component: () => import('@/views/AdminQR.vue'),
    meta: {
      title: 'Admin QR Codes | HelixIntel',
      layout: 'sidebar-layout',
      adminOnly: true,
    },
  },
  {
    path: '/admin/users/pending',
    name: 'admin-users-pending',
    component: () => import('@/views/AdminPendingUsers.vue'),
    meta: {
      title: 'Admin Pending Users | HelixIntel',
      layout: 'sidebar-layout',
      adminOnly: true,
    },
  },
  {
    path: '/admin/companies',
    name: 'admin-companies',
    component: () => import('@/views/AdminCompanies.vue'),
    meta: {
      title: 'Admin Companies | HelixIntel',
      layout: 'sidebar-layout',
      adminOnly: true,
    },
  },
  {
    path: '/admin/affiliates',
    name: 'admin-affiliates',
    component: () => import('@/views/AdminAffiliates.vue'),
    meta: {
      title: 'Admin Affiliates | HelixIntel',
      layout: 'sidebar-layout',
      adminOnly: true,
    },
  },
  {
    path: '/account/info',
    name: 'account-info',
    component: () => import('@/views/AccountInfo.vue'),
    meta: {
      title: 'Account Info | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/company/info',
    name: 'company-info',
    component: () => import('@/views/CompanyInfo.vue'),
    meta: {
      title: 'Company Info | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/company/users',
    name: 'company-users',
    component: () => import('@/views/CompanyUsers.vue'),
    meta: {
      title: 'Company Users | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/company/tags',
    name: 'company-tags',
    component: () => import('@/views/CompanyTags.vue'),
    meta: {
      title: 'Company Tags | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/company/fields',
    name: 'company-fields',
    component: () => import('@/views/CompanyCustomFields.vue'),
    meta: {
      title: 'Custom Fields | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/company/connections',
    name: 'company-connections',
    component: () => import('@/views/CompanyConnections.vue'),
    meta: {
      title: 'Company Connections | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/knowledge-center',
    name: 'knowledge-center',
    component: () => import('@/views/AffiliateKnowledgeCenter.vue'),
    meta: {
      title: 'Knowledge Center | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  // {
  //   path: '/vendor-hub/search',
  //   name: 'vendor-hub-serp',
  //   component: () => import('@/views/VendorHubResults.vue'),
  //   meta: {
  //     title: 'Vendor Hub | HelixIntel',
  //     layout: 'sidebar-layout',
  //   },
  // },
  // {
  //   path: '/vendor-hub/vendor/:id',
  //   name: 'vendor-hub-detail',
  //   component: () => import('@/views/ViewVendor.vue'),
  //   meta: {
  //     title: 'Vendor Hub | HelixIntel',
  //     layout: 'sidebar-layout',
  //   },
  // },
  {
    path: '/solutions',
    name: 'solutions',
    component: () => import('@/views/MarketplaceServices.vue'),
    meta: {
      title: 'Business Solutions | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/solutions/:id',
    name: 'solutions-view',
    component: () => import('@/views/MarketplaceServices.vue'),
    meta: {
      title: 'Business Solutions | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/solutions/:type/:id',
    name: 'solutions-form-view',
    component: () => import('@/views/MarketplaceServices.vue'),
    meta: {
      title: 'Business Solutions | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  // {
  //   path: '/vendor-hub',
  //   name: 'vendor-hub',
  //   component: () => import('@/views/VendorHubLandingPage.vue'),
  //   meta: {
  //     title: 'Vendor Hub | HelixIntel',
  //     layout: 'sidebar-layout',
  //   },
  // },
  // {
  //   path: '/vendor-hub/:id',
  //   name: 'marketplace-view',
  //   component: () => import('@/views/MarketplaceServices.vue'),
  //   meta: {
  //     title: 'Vendor Hub | HelixIntel',
  //     layout: 'sidebar-layout',
  //   },
  // },
  // {
  //   path: '/vendor-hub/:type/:id',
  //   name: 'marketplace-form-view',
  //   component: () => import('@/views/MarketplaceServices.vue'),
  //   meta: {
  //     title: 'Vendor Hub | HelixIntel',
  //     layout: 'sidebar-layout',
  //   },
  // },
  // {
  //   path: '/quotes',
  //   name: 'quotes',
  //   component: () => import('@/views/Quotes.vue'),
  //   meta: {
  //     title: 'Proposals | HelixIntel',
  //     layout: 'sidebar-layout',
  //   },
  // },
  {
    path: '/transactions',
    name: 'transactions',
    component: () => import('@/views/Transactions.vue'),
    meta: {
      title: 'Transactions | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  // {
  //   path: '/invoices',
  //   name: 'invoices',
  //   component: () => import('@/views/Invoices.vue'),
  //   meta: {
  //     title: 'Invoices | HelixIntel',
  //     layout: 'sidebar-layout',
  //   },
  // },
  {
    path: '/savings-scout/:id(\\d+)',
    name: 'incentive-report',
    component: () => import('@/views/ViewIncentive.vue'),
    meta: {
      title: 'Savings Scout Order | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/savings-scout/new',
    name: 'new-incentive-report',
    component: () => import('@/views/ViewIncentive.vue'),
    meta: {
      title: 'Savings Scout Order | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/savings-scout/:status?',
    name: 'incentives',
    component: () => import('@/views/Incentives.vue'),
    meta: {
      title: 'Savings Scout | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/account/notification-preferences',
    name: 'notification-preferences',
    component: () => import('@/views/AccountNotificationPreferences.vue'),
    meta: {
      title: 'Notification Preferences | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/account/password',
    name: 'account-password',
    component: () => import('@/views/AccountPassword.vue'),
    meta: {
      title: 'Account Password | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/views/Notifications.vue'),
    meta: {
      title: 'Notifications | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/notifications/dismissed',
    name: 'dismissed-notifications',
    component: () => import('@/views/Notifications.vue'),
    meta: {
      title: 'Notifications | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/work-manager/batch-notification/:id',
    name: 'view-bulk-notification',
    component: () => import('@/views/ViewBulkNotification.vue'),
    meta: {
      title: 'Notifications | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/tasks/batch-notification/:id',
    redirect: { name: 'view-bulk-notification' },
  },
  {
    path: '/customizations',
    name: 'customizations',
    component: () => import('@/views/Customizations.vue'),
    meta: {
      title: 'Customizations | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/sensors',
    name: 'sensors',
    component: () => import('@/views/Sensors.vue'),
    meta: {
      title: 'Sensors | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/sensor/:id',
    name: 'sensor-view',
    component: () => import('@/views/Sensors.vue'),
    meta: {
      title: 'Sensors | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import('@/views/RegistrationWizard.vue'),
    meta: {
      title: 'Account Set Up | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/qr-codes',
    name: 'qr-codes',
    component: () => import('@/views/Equipment.vue'),
    meta: {
      title: 'Generate QR Codes | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/qr-code/:code',
    name: 'use-qr-code',
    component: () => import('@/views/UseQRCode.vue'),
    meta: {
      title: 'Link QR Code | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/knowledge-center/embed/:id',
    name: 'knowledge-center-embed',
    component: () => import('@/views/KnowledgeCenterEmbed.vue'),
    meta: {
      title: 'Knowledge Center | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/csv-export/:id',
    name: 'csv-export',
    component: () => import('@/views/CsvExport.vue'),
    meta: {
      title: 'Csv Export | HelixIntel',
    },
  },
  {
    path: '/reservations/requests/new',
    name: 'reservation-request-new',
    component: () => import('@/views/ReservationRequestCreate.vue'),
    meta: {
      title: 'New Reservation Request | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/reservations/requests/processed/:id?',
    name: 'reservation-requests-processed',
    component: () => import('@/views/ReservationRequests.vue'),
    meta: {
      title: 'Processed Reservation Requests | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/reservations/requests/:id?',
    name: 'reservation-requests',
    component: () => import('@/views/ReservationRequests.vue'),
    meta: {
      title: 'Reservation Requests | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/reservations/calendar/archived/:id?',
    name: 'reservations-calendar-archived',
    component: () => import('@/views/Reservations.vue'),
    meta: {
      title: 'Archived Reservations Calendar | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/reservations/calendar/:id?',
    name: 'reservations-calendar',
    component: () => import('@/views/Reservations.vue'),
    meta: {
      title: 'Reservations Calendar | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/reservations/archived/:id?',
    name: 'reservations-archived',
    component: () => import('@/views/Reservations.vue'),
    meta: {
      title: 'Archived Reservations | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '/reservations/:id?',
    name: 'reservations',
    component: () => import('@/views/Reservations.vue'),
    meta: {
      title: 'Reservations | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
  {
    path: '*',
    component: () => import('@/views/PageNotFound.vue'),
    meta: {
      title: 'Page Not Found | HelixIntel',
      layout: 'sidebar-layout',
    },
  },
];

export default routes;
